import React, { useState, useContext } from "react"
import styled from "styled-components"
import Seo from "@components/seo"
// import { Breadcrumbs } from "@components"
import { Button } from "@components/common/form"
import {
  ErrorMessage,
  SuccessMessage,
  Input,
  Label,
  OutputLabel,
  // TextArea,
} from "@components/common/form"
import { FirebaseContext } from "../components/Firebase"

export default function InvestorConnect() {
  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessName: "",
    phone: "",
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (data.email !== data.confirmEmail) {
      setErrorMessage("Emails do not match")
    }
    if (!data.email.includes("@")) {
      return alert("Please enter a valid email")
    } else {
      firebase.db
        .collection("consultation")
        .add({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          confirmEmail: data.confirmEmail,
          phone: data.phone,
          companyName: data.companyName,
          phone: data.phone,
        })
        .then(() => {
          setSuccessMessage(
            `Hello, ${data.firstName}. Thank you for your interest in working with us. We will be in touch with you shortly.`
          )
        })
        .catch(error => {
          setErrorMessage(error.message)
        })

      setTimeout(() => {
        setData({
          firstName: "",
          lastName: "",
          email: "",
          confirmEmail: "",
          phone: "",
          companyName: "",
          phone: "",
        })
      }, 3000)
    }
  }

  return (
    <>
      <Seo title="Book a Consultation" />
      <Container>
        <FormContainer>
          <OutputLabel label="Book a Consultation" />
          <FormWrap onSubmit={handleSubmit}>
            {/* <FormTopRow> */}
            <FlexItem>
              <Label htmlFor="first name">first name *</Label>
              <Input
                name="first name"
                placeholder="first name"
                onChange={e => {
                  setData({
                    ...data,
                    firstName: e.target.value,
                  })
                }}
                value={data.firstName}
                required
              />
            </FlexItem>
            <FlexItem>
              <Label htmlFor="last name">last name *</Label>
              <Input
                name="last name"
                placeholder="last name"
                onChange={e => {
                  setData({
                    ...data,
                    lastName: e.target.value,
                  })
                }}
                value={data.lastName}
                required
              />
            </FlexItem>
            <FlexItem>
              <Label htmlFor="company">company *</Label>
              <Input
                name="company"
                placeholder="company"
                onChange={e => {
                  setData({
                    ...data,
                    companyName: e.target.value,
                  })
                }}
                value={data.companyName}
                required
              />
            </FlexItem>
            <FlexItem>
              <Label htmlFor="phone">phone number </Label>
              <Input
                name="phone"
                placeholder="phone"
                onChange={e => {
                  setData({
                    ...data,
                    phone: e.target.value,
                  })
                }}
                value={data.phone}
              />
            </FlexItem>
            {/* </FormTopRow> */}
            {/* <FormBottomRow> */}
            <FlexItem>
              <Label htmlFor="email">email *</Label>
              <Input
                placeholder="email"
                type="email"
                onChange={e => {
                  setData({
                    ...data,
                    email: e.target.value,
                  })
                }}
                value={data.email}
                required
              />
            </FlexItem>
            <FlexItem>
              <Label htmlFor="confirm email">confirm email *</Label>
              <Input
                name="confirm email"
                placeholder="confirm email"
                onChange={e => {
                  setData({
                    ...data,
                    confirmEmail: e.target.value,
                  })
                }}
                value={data.confirmEmail}
                required
              />
            </FlexItem>
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {!!successMessage && (
              <SuccessMessage>{successMessage}</SuccessMessage>
            )}
            {/* </FormBottomRow> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <NextButton type="submit">
                submit
                <ArrowRight
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <Path
                    d="M3.33334 7.99967H12.6667M12.6667 7.99967L8.00001 3.33301M12.6667 7.99967L8.00001 12.6663"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </ArrowRight>
              </NextButton>
            </div>
          </FormWrap>
        </FormContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 680px;
  margin-inline: auto;
`

const Banner = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.primaryColor};
  /* color: ${p => p.theme.bodyParagraphColor}; */
  padding: 24px;
  border-radius: 4px;
  /* border: 1px solid ${p => p.theme.borderColor}; */
  min-height: 25vh;
`

const FormContainer = styled.div`
  min-height: 100vh;
  padding: 5rem 0;
`

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  padding: 0 8px;
`

const FormTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

const FormBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 24px; */
  gap: 24px;
`

const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
`

const NextButton = styled(Button)`
  margin-top: 1rem;
  justify-content: flex-end;
  /* padding: 0.5rem 1rem; */
`

const Path = styled.path``

const ArrowRight = styled.svg`
  height: 16px;
  width: 16px;
  margin: 0.5rem;
  vertical-align: middle;

  ${Path} {
    stroke: ${p => p.theme.fillColor};
  }
`
